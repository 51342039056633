.liveStormRow {
    display: grid;
    grid-template-columns: fit-content(100%) 1fr; /* First column fits content, second takes remaining space */
    align-items: flex-startf;
    justify-content: center; /* Aligns the grid items horizontally */
    gap: 0.8vw; /* Space between columns */
    margin: 0 0vw;
    font-size: 1rem;
}

.warning {
    animation: fadeIn 1s ease-in-out forwards;
    color: darkorange;
    margin-top: 0.5vh;
    text-align: center;
    font-size: smaller;
    flex-grow: 1;
    justify-content: center;
}

.resultLabel {
    font-weight: bold;
    width: fit-content;
    flex-wrap: nowrap;
    margin-bottom: 1.3rem;
    margin-top: 0.2rem;
}

.resultsWrapper {
    animation: fadeIn 1s ease-in-out forwards;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.result {
    display: flex;
    justify-content: center;
    justify-content: space-evenly; /* Dynamically distribute available space */
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 1vw; /* Start with no explicit gap */
    min-width: 60%;
}

.fixedSizeContainer {
    display: block;
    gap: 0.5vw;
    align-items: center;
}

.fixedSizeContainer > div {
    display: flex;
    align-items: center;
    font-family: monospace;
}

.fixedSizeLabel {
    width: 1vw;
}

.additionalInfoContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5vw;
}

.hidden {
    animation: neverFadeIn 1s ease-in-out forwards;
}

.loading {
    animation: infiniteFadeIn 2s ease-in-out infinite;
    display: flex;
    justify-content: center;
    color: rgb(255, 0, 0);
    margin-bottom: 1.3rem;
    margin-top: 0.2rem;
}

.alert {
    animation: fadeIn 1s ease-in-out forwards;
    justify-content: center;
    color: red;
    text-align: center;
    flex-grow: 1;
    margin-bottom: 1.3rem;
    margin-top: 0.2rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes infiniteFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes neverFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 0;
    }
}
