/* SiteDetailsForm.css */

.site-details-form {
    display: flex;
    width: 60vw;
    background-color: #f0f0f0;
    padding: 1.25rem;
    border: 1px solid #ccc;
    justify-content: space-between;
    margin-top: 3vh;
}

.form-group {
    width: auto;
    /* overflow: auto; */
}

/* select,
input {
    padding: 0.625rem;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 0.3125rem;
    font-size: 1rem;
} */

.liveStormRow {
    display: flex;
    align-items: center;
    margin: 0 1vw;
    gap: 2vw;
}

.resultLabel {
    font-weight: bold;
    width: auto;
}

.resultsWrapper {
    animation: fadeIn 1s ease-in-out forwards;
    display: flex;
    width: auto;
    flex-grow: 1;
    justify-content: space-between;
    gap: 1vw;
}

.resultItem {
    font-weight: normal;
}

.loading {
    animation: infiniteFadeIn 2s ease-in-out infinite;
    display: flex;
    width: 100;
    flex-grow: 1;
    justify-content: center;
    color: rgb(255, 0, 0);
}

.hidden {
    animation: fadeOut 0.1s ease-in-out forwards;
}

@keyframes fadeOut {
    from {
        opacity: 0;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

@keyframes infiniteFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 100;
    }

    100% {
        opacity: 0;
    }
}
