.container {
    display: grid;
    gap: 0.6rem 0;
}

.areas-field {
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 80px;
    gap: 1rem;
}

.input-control-error {
    font-size: 14px;
    color: #dc3545;
}
