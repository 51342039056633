.image-row {
    display: flex;
    align-items: center;
    margin: 20px;
    /*  padding: 10px; 
    gap: 50px; */
}

.image-grid {
    display: flex;
    gap: 50px;
    overflow-x: auto;
    border-left: 3px solid black;
    align-content: center;
    padding: 15px 50px;
}

.image-grid img {
    width: 100px;
    height: auto;
    border: 1px solid #ddd;
    cursor: pointer;
}

.MaterialBox {
    display: block;
}
