.ResultsTable {
    margin-top: 3vh;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid #4b4b4b;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.table thead {
    background-color: #e1e1e1;
}

.table tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.table tbody tr:nth-child(even) {
    background-color: #e1e1e1;
}
