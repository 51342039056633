.tc-calculator-container {
    --border-color: #aaa;
    border: 1px solid var(--border-color);
    border-radius: 5px;
}

.tc-calculator-container .tc-calculator-header,
.tc-calculator-container .tc-calculator-body,
.tc-calculator-container .tc-calculator-footer {
    padding: 1rem;
}

.tc-calculator-container .tc-calculator-header {
    border-bottom: 1px solid var(--border-color);
}

.tc-calculator-container .tc-calculator-body {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 1rem;
}

.tc-calculator-container .tc-calculator-footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--border-color);
    align-items: center;
}

.tc-calculator-container .tc-calculator-footer > * {
    margin: 0;
}
