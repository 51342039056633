.rwt-calculator {
    --border-color: #aaa;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rwt-calculator-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    grid-gap: 1rem;
}

/* @media (max-width: 1750px) { */
/*     .rwt-calculator-form { */
/*         grid-template-columns: repeat(2, 1fr); */
/*     } */
/* } */

.doc-container {
    padding: 2rem;
    border-radius: 5px;
    background: #ffffff66;
}

.input-control-container {
    /* display: grid; */
    /* grid-template-columns: 1fr 2fr; */
    /* align-items: center; */
    /* grid-gap: 8px; */
}

.input-control-container .input-control-error {
    font-size: 14px;
    color: #dc3545;
}

.input-control-container label {
    font-size: 14px;
}

/* Remove default controls in input */
.input-control-container input::-webkit-outer-spin-button,
.input-control-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove default controls in input */
.input-control-container input[type='number'] {
    -moz-appearance: textfield;
}

.rwt-calculator .btn-orange {
    background: #ff9f4d;
    color: white;
}

.rwt-calculator .btn-orange:hover {
    background: #ed9347;
    color: white;
}

.rwt-calculator .btn-orange:disabled {
    background: #ff9f4dee;
    color: white;
}

/* NOTE: uncomment this to increase the width of the popover */
.popover {
    max-width: 600px;
}

.popover p {
    font-size: 12px;
}
