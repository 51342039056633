/* containers */
.app-container {
    background-color: #73cddf;
    min-width: 900px;
    min-height: 100vh;
}

.panInnerCalc {
    min-width: 700px;
    min-height: 70vh;
    /* margin-inline: auto; */
    background: rgb(229, 229, 229);
    border-radius: 10px;
    border-width: 3px;
    border-style: solid;
    border-color: #536380;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 20px;
    position: relative;
    font-family: 'Montserrat', sans-serif;
}

/* global styles */
.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.background-image-deep-blue {
    /* background-image: linear-gradient(
    to right,
    #457fca 0%,
    #5691c8 51%,
    #457fca 100%
  ); */
    background: #73cddf;
}

.tool-link-buttons {
    gap: 5px;
    justify-content: right;
}

.tool-link-button {
    background: #ff4742;
    border: 1px solid #ff4742;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-family: nunito, roboto, proxima-nova, 'proxima nova', sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 40px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.tool-link-button:hover,
.tool-link-button:active {
    background-color: white;
    background-position: 0 0;
    color: black;
}

.tool-link-button:active {
    opacity: 0.5;
}

.project-info-container {
    border: 3px solid #7290cf;
    /* Border color and thickness */
    border-radius: 5px;
    /* Rounded corner radius */
    background-color: #cedcf8;
    /* Panel background color */
    padding: 15px;
    /* Padding inside the panel */
    /* margin-inline: auto; */
    margin-bottom: 10px;
    height: 40vh;
    max-height: 300px;
    min-width: 18vw;
    width: 100%;
    font-weight: bold;
    margin: 0 auto;
}

.state-upload-download-button-container {
    border: 3px solid #7290cf;
    /* Border color and thickness */
    border-radius: 5px;
    /* Rounded corner radius */
    background-color: #ded5f5;
    /* Panel background color */
    padding: 15px;
    /* Padding inside the panel */
    width: 22vw;
    min-width: 22vw;
    max-width: 200px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* width > 1000px */
.tutorial-item {
    border: 1px solid grey;
    flex: 1;
    grid-template-columns: 20px 1fr 20px;
    min-height: 30vh;
    padding: 30px;
    margin-right: 2vh;
    /* beautiful border */
    background: #fff;
    font-family: Montserrat, sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    color: black;
    text-shadow: 3px 3px 1px #bfecf7;
}

/* width <= 1000px */
.tutorial-container-small {
    border: 1px solid grey;
    flex-direction: column;
}

.tutorial-item-small {
    background: #fff;
    color: black;
    text-align: left;
    font-size: 18px;
    padding: 5vh;
    grid-template-columns: 20px 1fr 20px;
    min-height: 30vh;
    margin-right: 2vh;
    margin-block: 2vh;
    line-height: 30px;
}

.button-blink {
    transition: 0.6s;
    overflow: hidden;
}

.button-blink:focus {
    outline: 0;
}

.button-blink::before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.5;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
}

.button-blink::after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 100%;
    left: 30px;
    top: 0;
    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
}

.button-blink:hover {
    cursor: pointer;
}

.button-blink:hover:before {
    transform: translateX(300px) skewX(-15deg);
    opacity: 0.6;
    transition: 0.7s;
}

.button-blink:hover:after {
    transform: translateX(300px) skewX(-15deg);
    opacity: 1;
    transition: 0.7s;
}

.hover-pointer:hover {
    cursor: pointer;
}
.hover-gray:hover {
    background: #808080;
}

@keyframes shake {
    50%,
    90% {
        transform: rotate(-0.5deg);
    }
    55%,
    85% {
        transform: rotate(0.5deg);
    }
    60%,
    80%,
    70% {
        transform: rotate(-1deg);
    }
    65%,
    75% {
        transform: rotate(1deg);
    }
}
.element-shake {
    animation: shake 3s infinite;
    transform-origin: center;
}

.opacity-0 {
    opacity: 0;
}

.osd-blue-border {
    border: 2px solid #7290cf;
    border-radius: 10px;
    padding: 20px 10px;
}
.osd-light-blue-bg {
    background-color: #cedcf8;
}

.gap-1 {
    gap: 5px;
}
.gap-2 {
    gap: 10px;
}
.gap-3 {
    gap: 15px;
}
.gap-4 {
    gap: 20px;
}
.gap-5 {
    gap: 25px;
}
