.rwt-calculator-container {
    --border-color: #aaa;
    border-radius: 5px;
    padding: 0;
}

.rwt-calculator-container .rwt-calculator-header,
.rwt-calculator-container .rwt-calculator-body,
.rwt-calculator-container .rwt-calculator-footer {
    padding: 1rem;
}

.rwt-calculator-container .rwt-calculator-header {
    border-bottom: 1px solid var(--border-color);
}

.rwt-calculator-container .rwt-calculator-header .rwt-calculator-title {
    font-size: 1.25rem;
}

.rwt-calculator-container .rwt-calculator-body {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-buttom: 2rem;
    gap: 1rem;
}

.rwt-calculator-container .rwt-calculator-body .custom-bootstrap-select-input {
    /* NOTE: fix select styles on safari browsers (workaround for bootstrap 4) */
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background: #fff
        url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
        right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    -webkit-appearance: none;
    appearance: none;
}

.rwt-calculator-container .rwt-calculator-body .custom-bootstrap-select-input:disabled {
    background-color: #e9ecef;
}

.rwt-calculator-container .rwt-calculator-footer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-top: 1px solid var(--border-color);
}

.rwt-calculator-container .rwt-calculator-footer .result {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
}

.rwt-calculator-container .rwt-calculator-footer .result > *,
.rwt-calculator-container .rwt-calculator-footer > * {
    margin: 0;
}
