.tc-calculator-modal {
    width: 80vw;
    max-width: 50vw;
    margin-top: 5vh;
}

.modal-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.tc-calculator-forms {
    margin-bottom: 10px;
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}