.radial-cell-1 { grid-area: radial-cell-1 }
.radial-cell-2 { grid-area: radial-cell-2 }
.radial-cell-3 { grid-area: radial-cell-3 }
.radial-cell-4 { grid-area: radial-cell-4 }
.radial-cell-5 { grid-area: radial-cell-5 }
.radial-cell-6 { grid-area: radial-cell-6 }
.header-1 { grid-area: header-1; }
.header-2 { grid-area: header-2; }
.header-3 { grid-area: header-3; }
.header-4 { grid-area: header-4; }
.header-5 { grid-area: header-5; }
.header-6 { grid-area: header-6; }
.cell-2-1 {  grid-area: cell-2-1; }
.cell-2-2 {  grid-area: cell-2-2; }
.cell-2-3 {  grid-area: cell-2-3; }
.cell-2-4 {  grid-area: cell-2-4; }
.cell-2-5 {  grid-area: cell-2-5; }
.cell-3-1 {  grid-area: cell-3-1; }
.cell-3-2 {  grid-area: cell-3-2; }
.cell-3-3 {  grid-area: cell-3-3; }
.cell-3-4 {  grid-area: cell-3-4; }
.cell-3-5 {  grid-area: cell-3-5; }
.cell-4-1 {  grid-area: cell-4-1; }
.cell-4-2 {  grid-area: cell-4-2; }
.cell-4-3 {  grid-area: cell-4-3; }
.cell-4-4 {  grid-area: cell-4-4; }
.cell-4-5 {  grid-area: cell-4-5; }
.cell-5-1 {  grid-area: cell-5-1; }
.cell-5-2 {  grid-area: cell-5-2; }
.cell-5-3 {  grid-area: cell-5-3; }
.cell-5-4 {  grid-area: cell-5-4; }
.cell-5-5 {  grid-area: cell-5-5; }
.cell-6-1 {  grid-area: cell-6-1; }
.cell-6-2 {  grid-area: cell-6-2; }
.cell-6-3 {  grid-area: cell-6-3; }
.cell-6-4 {  grid-area: cell-6-4; }
.cell-6-5 {  grid-area: cell-6-5; }

.grid-container {
  display: grid;
  grid-template-areas:
  'radial-cell-1 header-1 header-2 header-3 header-4 header-4'
  'radial-cell-1 header-1 header-2 header-3 header-5 header-6'
  'radial-cell-2 cell-2-1 cell-2-2 cell-2-3 cell-2-4 cell-2-5'
  'radial-cell-3 cell-3-1 cell-3-2 cell-3-3 cell-3-4 cell-3-5'
  'radial-cell-4 cell-4-1 cell-4-2 cell-4-3 cell-4-4 cell-4-5'
  'radial-cell-5 cell-5-1 cell-5-2 cell-5-3 cell-5-4 cell-5-5'
  'radial-cell-6 cell-6-1 cell-6-2 cell-6-3 cell-6-4 cell-6-5'
  ;
}

.grid-container > div {
  text-align: left;
  padding: 3px 10px;
  font-size: 16px;
  border:1px solid black;
  background-color: white;
}
.grid-container .radial-cell-1 {
  border:none;
  background-color: inherit;
}
.grid-container .radial-cell-2 {
  border:none;
  background-color: inherit;
}
.grid-container .radial-cell-3 {
  border:none;
  background-color: inherit;
}
.grid-container .radial-cell-4 {
  border:none;
  background-color: inherit;
}
.grid-container .radial-cell-5 {
  border: none;
  background-color: inherit;
}
.grid-container .radial-cell-6 {
  border: none;
  background-color: inherit;
}