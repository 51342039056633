.auditor-form {
    background-color: #f0f0f0;
    padding: 30px;
    border: 1px solid #ccc;
    transition: height 0.3s ease;
    overflow: hidden;
    justify-content: center;
    margin: 3vh 0;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

.auditor-form h3 {
    font-size: 1.5rem;
    position: relative;
    color: #040f19;
    z-index: 10;
    margin: 0;
    padding: 0;
}
.auditor-form h4 {
    font-weight: 900;
    font-size: 1rem;
    color: rgb(4, 2, 114);
}

.auditor-form label {
    display: block;
    margin-bottom: 0.625rem;
}

.form-header {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: auto;
}

.auditor-form .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    white-space: nowrap;
    justify-content: normal;
    width: auto;
    font-size: x-large;
}

.auditor-form .toggle-checkbox {
    margin: 0;
    padding: 0;
    width: auto;
}

.toggle-button {
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}

.arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
    transition: transform 0.3s ease;
}

.up {
    transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
}

.closed {
    height: auto;
}

.auditor-form input,
select {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    font-size: 16px;
    margin-bottom: 10px;
}
.auditor-form input {
    padding: 0.5rem;
    height: 6vh;
}

.auditor-form input:disabled {
    background-color: #e8e4e4;
}

.auditor-form select {
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.625rem;
    padding: 0.5rem 2rem 0.5rem 1rem;
    height: 6vh;
    font-size: 1rem;
    cursor: pointer;
    outline: none;
    transition:
        border-color 0.3s,
        box-shadow 0.3s;

    background-image: linear-gradient(45deg, transparent 50%, #666 50%),
        linear-gradient(135deg, #666 50%, transparent 50%);
    background-position:
        calc(100% - 15px) center,
        calc(100% - 10px) center;
    background-size:
        5px 5px,
        5px 5px;
    background-repeat: no-repeat;
}

.auditor-form select:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    border-style: solid;
    border-width: 0.5rem 0.5rem 0;
    border-color: #666 transparent transparent transparent;
}

.auditor-form select:disabled {
    background-color: #e8e4e4;
}

.auditor-table {
    width: 100%;
}

.table-row td {
    padding: 10px;
    vertical-align: top;
}

.file-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.file-name {
    margin-bottom: 10px;
    color: #555;
}

.download-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
}

.success-message {
    color: green;
}

.button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
}

.button-success {
    background-color: #28a745;
    color: white;
}

.button-danger {
    background-color: #dc3545;
    color: white;
}

.button-info {
    background-color: #17a2b8;
    color: white;
}

.button:hover {
    opacity: 0.9;
}

.small-text {
    font-size: 11px;
}

.upload-buttons {
    display: flex;
    justify-content: center;
    gap: 1vw;
}

.auditor-form-section {
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    width: 100%;
    white-space: no-wrap;
    /* text-align: center; */
    gap: 5px;
    border: 1px solid rgb(47, 47, 47);
    margin: 2vh 0;
    border-radius: 10px;
}

.button-light-green {
    background-color: #82dcca;
    color: white;
    border: 2px solid transparent;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.8vw;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    transition:
        background-color 0.4s,
        color 0.4s,
        border-color 0.4s;
    height: 12vh;
    flex: 1;
    margin: 0 3vw;
}

.tool-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1vw;
    padding: 20px;
    height: 12vh;
    font-size: 18px;
    color: #040f19;
    font-weight: 600;
    flex: 1;
}

.button-light-blue {
    background-color: #007bff;
}
.button-light-blue:disabled {
    opacity: 0.55;
    cursor: not-allowed;
    background-color: #007bff;
}
.button-green {
    background-color: #88dccc;
}

.button-green:hover {
    background-color: #6edec9;
}

.button-light-green:hover {
    background-color: white;
    color: black;
    border-color: #a3e4d7;
}

.button-light-green:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    background-color: #7ad2c0;
}

.button-medium-green {
    background-color: #24ca82;
    color: white;
    border: 2px solid transparent;
    padding: 10px 10px 10px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.8vw;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    transition:
        background-color 0.4s,
        color 0.4s,
        border-color 0.4s;
    height: 12vh;
}

.button-medium-green:hover {
    background-color: white;
    color: black;
    border-color: #4caf50;
}

.button-medium-green:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    background-color: #24ca82;
}

.button-dark-green {
    background-color: #048556;
    color: white;
    border: 2px solid transparent;
    padding: 10px 10px 10px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.8vw;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
    transition:
        background-color 0.4s,
        color 0.4s,
        border-color 0.4s;
    height: 12vh;
}

.button-dark-green:hover {
    background-color: white;
    color: black;
    border-color: #1e8449;
}

.button-dark-green:disabled {
    opacity: 0.65;
    cursor: not-allowed;
    background-color: #08a76d;
}

.custom-arrow {
    position: relative;
    display: inline-block;
    width: 4vw;
    height: 3.5vh;
    vertical-align: middle;
}

.custom-arrow::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 75%;
    height: 50%;
    background-color: black;
}

.custom-arrow::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 1.5vh solid transparent;
    border-bottom: 1.5vh solid transparent;
    border-left: 1.2vw solid black;
}
.custom-arrow-middle {
    position: relative;
    display: inline-block;
    width: 5.2vw;
    height: 3.5vh;
    vertical-align: middle;
}

.custom-arrow-middle::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 80%;
    height: 50%;
    background-color: black;
}

.custom-arrow-middle::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 90%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 1.5vh solid transparent;
    border-bottom: 1.5vh solid transparent;
    border-left: 1.2vw solid black;
}

.tool-selector-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1vw;
}

.divider {
    border: 1px solid rgba(0, 0, 0, 0.48);
    width: 90%;
}

.buttonIcon {
    width: 40%;
    height: 120%;
}

.resultsContainer {
    display: flex;
    justify-content: space-between;
    gap: 2vw;
}

.resultsContainer h5 {
    font-size: 0.9rem;
    margin: 1rem 1rem 0 0.5rem;
    font-weight: 600;
    color: #0a0b42;
}

.overallResults label {
    margin-top: 0.5vh;
    color: #15165e;
    text-align: right;
    font-weight: 400;
}

.alert {
    color: red;
    animation: infiniteFadeIn 2s ease-in-out infinite;
    text-align: center;
}

.reductionTable {
    width: 50%;
    margin: 1rem 0;
}

@media (max-width: 1500px) {
    .reductionTable {
        width: 60%;
    }
}

@media (max-width: 1300px) {
    .reductionTable {
        width: 80%;
    }
}

@media (max-width: 992px) {
    .reductionTable {
        width: 100%;
    }
}

@keyframes infiniteFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 100;
    }

    100% {
        opacity: 0;
    }
}
