.osd-results-table {
    display: grid;
    grid-template:
        'a a a b b b b b b b b b' auto
        'a a a d d d e e e f f f' auto
        'a a a g h i j k l m n o' auto
        'p p p q r s t u v w x y' auto;
    text-align: center;
    color: white;
    background-color: #508bc9;
    margin-block: 10;
    max-width: 1500px;
}
.osd-storage-type-selector {
    display: flex;
    justify-content: space-between;
    margin-top: 5vh;
    padding: 1.5rem;
    border: 1px solid black;
    background-color: #cfcece;
    border-radius: 0.3rem;
}

.osd-results-cell {
    flex-grow: 1;
}

.osd-results-cell-a {
    grid-area: a;
    padding: 5px;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
}
.osd-results-cell-b {
    grid-area: b;
    font-weight: bold;
    border-bottom: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-d {
    grid-area: d;
    background-color: #ccb564;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-e {
    grid-area: e;
    background-color: #6cb86f;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-f {
    grid-area: f;
    background-color: #5073c9;
    border-bottom: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-g {
    grid-area: g;
    background-color: #ccb564;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-h {
    grid-area: h;
    background-color: #ccb564;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-i {
    grid-area: i;
    background-color: #ccb564;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-j {
    grid-area: j;
    background-color: #6cb86f;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-k {
    grid-area: k;
    background-color: #6cb86f;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-l {
    grid-area: l;
    background-color: #6cb86f;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-m {
    grid-area: m;
    background-color: #5073c9;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-n {
    grid-area: n;
    background-color: #5073c9;
    border-right: rgb(238, 238, 238) 2px solid;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-o {
    grid-area: o;
    background-color: #5073c9;
    border-bottom: rgb(238, 238, 238) 2px solid;
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}
.osd-results-cell-p {
    grid-area: p;
    border-right: rgb(238, 238, 238) 2px solid;
}
.osd-results-cell-q {
    grid-area: q;
    background-color: #ccb564;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-r {
    grid-area: r;
    background-color: #ccb564;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-s {
    grid-area: s;
    background-color: #ccb564;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-t {
    grid-area: t;
    background-color: #6cb86f;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-u {
    grid-area: u;
    background-color: #6cb86f;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-v {
    grid-area: v;
    background-color: #6cb86f;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-w {
    grid-area: w;
    background-color: #5073c9;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-x {
    grid-area: x;
    background-color: #5073c9;
    border-right: rgb(238, 238, 238) 2px solid;
    padding-top: 10px;
}
.osd-results-cell-y {
    grid-area: y;
    background-color: #5073c9;
    padding-top: 10px;
}

.osd-results-table-yellow-button {
    background: linear-gradient(to bottom, #e3eb02 5%, #a16b0d 100%);
    border-color: #007bff;
    text-shadow: 0px 1px 0px #283966;
}
.osd-results-table-green-button {
    background: linear-gradient(to bottom, #00f752 5%, #067a0c 100%);
    border-color: #007bff;
    text-shadow: 0px 1px 0px #283966;
}
.osd-results-table-blue-button {
    background: linear-gradient(to bottom, #00b7ff 5%, #084799 100%);
    border-color: #007bff;
    text-shadow: 0px 1px 0px #283966;
}
