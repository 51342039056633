.overallResults {
    width: 60vw;
    background-color: #f0f0f0;
    padding: 1.25rem;
    border: 1px solid #ccc;
    margin-top: 3vh;
}
.resultsContainer {
    display: flex;
    justify-content: space-between;
    gap: 2vw;
}

.resultsContainer h5 {
    font-size: 0.9rem;
    margin: 1rem 1rem 0 0.5rem;
    font-weight: 600;
    color: #0a0b42;
}

.overallResults label {
    margin-top: 0.5vh;
    color: #15165e;
    text-align: right;
    font-weight: 400;
}

.alert {
    color: red;
    animation: infiniteFadeIn 2s ease-in-out infinite;
    text-align: center;
}

@keyframes infiniteFadeIn {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 100;
    }

    100% {
        opacity: 0;
    }
}
