/* AreaForm.css */

.area-form {
    width: 60vw;
    background-color: #f0f0f0;
    padding: 1.25rem;
    border: 1px solid #ccc;
    justify-content: space-between;
    margin-top: 3vh;
}

.area-row {
    display: flex;
    justify-content: space-between;
    gap: 1vw;
    align-items: flex-end;
    margin-top: 2vh;
    margin-bottom: 1vh;
}

.form-group {
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    width: 1vw;
}

.form-group:nth-child(1) {
    flex-grow: 3;
}

.form-group:nth-child(2) {
    flex-grow: 1.5;
}

.form-group:nth-child(3) {
    flex-grow: 1.5;
}

.form-group:nth-child(4) {
    flex-grow: 4;
}

.form-group:nth-child(5) {
    flex-grow: 1.7;
}

.form-group:nth-child(6) {
    flex-grow: 1.1;
}

.area-form button {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.area-form button:hover {
    background-color: #45a049;
}

.icon {
    margin-bottom: 1vh;
    font-size: 1.3rem;
    color: red;
    cursor: pointer;
}

.area-form label {
    text-align: center;
}
