.tooltip {
}

.tooltip-icon {
    margin: 0 5px;
    transition: color ease-in-out 0.2s;
    cursor: default;
}

.tooltip-icon.link {
    cursor: pointer;
}

.tooltip-content {
}

.tooltip-content img {
    width: 100%;
}
