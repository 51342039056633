.tc-calculator {
    --border-color: #aaa;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tc-calculator-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(50px, 1fr));
    grid-gap: 1rem;
}

@media (max-width: 1750px) {
    .tc-calculator-form {
        grid-template-columns: repeat(2, 1fr);
    }
}

.span-two-columns {
    grid-column: span 2;
}

.doc-container {
    padding: 2rem;
    border-radius: 5px;
    background: #ffffffa0;
}

.input-control-container .input-control-error {
    font-size: 14px;
    color: #dc3545;
}

.input-control-container label {
    font-size: 14px;
}

/* Remove default controls in input */
.input-control-container input::-webkit-outer-spin-button,
.input-control-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Remove default controls in input */
.input-control-container input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}
