/* SummaryComponent.module.css */

.summary-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 60vw;
    margin-top: 3vh;
}

.summary-item {
    margin-bottom: 10px;
    width: 50%;
    justify-content: center;
    display: flex;
    column-gap: 0.5vw;
}

.summary-label {
    font-weight: bold;
}

.summary-value {
    /* margin-left: 1vw; */
    font-weight: normal;
}
