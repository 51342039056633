/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

.tab-active {
    background-color: var(--active-bg-color);
}

/* Style the buttons inside the tab */
.tab button {
    /* float: left; */
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 16px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: var(--active-bg-color);
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}
