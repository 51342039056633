#noanim-tab-example-tab-Swinburne {
    border-right: groove;
    border-left: groove;
}

.ad_box_ {
    flex-direction: row;
    flex: 80%;
    margin-top: 1%;
    position: relative;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    min-width: 300px;
    border-width: 3px;
    border-radius: 10px;
    background-color: #cedcf8;
    border-color: #7290cf;
    border-style: solid;
    padding: 1%;
}

.buttonClass {
    background: none;
    border: none;
    padding: 0;
    outline: inherit;
    margin: 1%;
}

.osdsAdImg {
    /* height: 40%; */
    max-height: 37px;
}

.logoDiv {
    margin: 0.05%;
    /* height: 40%; */
}

.spanclass {
    font-family: 'Montserrat', sans-serif;
    margin: 0.05%;
    font-size: 1.25rem;
    color: rgb(4, 38, 148);
}

.osds_ads {
    background: #eaf1ff;
    border-radius: 10px;
    border-width: 3px;
    border-style: solid;
    border-color: #536380;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 20px;
    position: relative;
    font-family: 'Montserrat', sans-serif;
}

.panNodeWQ {
    background: #cedcf8;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.panNode {
    background: #eaf1ff;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    z-index: 10;
    min-width: 800px;
    max-width: 65vw;
}

.panNode_pervious {
    background: #eaf1ff;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.panNodePreDev {
    background: #fff0ba;
    border-radius: 5px;
    border-color: #d9c16a;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
    z-index: 11;
}

.panNode_perviousPreDev {
    background: #fff0ba;
    border-radius: 5px;
    border-color: #d9c16a;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
    max-width: 707px;
}

.panNodePostDev {
    background: #b3e3b5;
    border-radius: 5px;
    border-color: #6cb86f;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    position: static;
    font-family: 'Montserrat', sans-serif;
    z-index: 10;
}

.panNode_perviousPostDev {
    background: #b3e3b5;
    border-radius: 5px;
    border-color: #6cb86f;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
    max-width: 707px;
}

.panNode_data {
    background: #eaf1ff;
    border: 3px solid #7290cf;
    border-radius: 5px;
    -webkit-border-radius: 10px;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
}

.panNode_dataPreDev {
    background: #fff0ba;
    border-radius: 5px;
    border-color: #d9c16a;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.panNode_dataPostDev {
    background: #b3e3b5;
    border-radius: 5px;
    border-color: #6cb86f;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin-bottom: 10px;
    padding: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.panNodeExisting {
    background: #f1dc92;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.panNodeDeveloped {
    background: #9cdb9e;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 10px;
    position: static;
    font-family: 'Montserrat', sans-serif;
}

.panNode_osd {
    border-style: solid;
    margin-bottom: 20px;
    border-radius: 10px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 20px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.postDevPerviousNodeAlert {
    margin-top: 10px;
    z-index: 0;
}

.spacer {
    width: 70px;
}

.progressBarDiv {
    margin-top: 10px;
}

.small_spacer {
    width: 15px;
}

.lableFormatterOSD {
    margin: 2px 5px;
    width: 80px;
    text-align: right;
}

.lableFormatterAEP {
    margin: 2px 5px;
    width: 80px;
    text-align: right;
}

.tableChoice {
    margin-bottom: 5px;
}

.checkbox {
    width: auto;
    margin-right: 5px;
    margin-top: 5px;
}

.checkboxPad {
    padding: 10px;
    margin: 0;
}

.checkboxPad_hasWQ {
    padding: 10px;
    margin: 0;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    background-color: #cedcf8;
}

.checkboxPad_hasOsd {
    padding: 10px;
    margin: 0;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    background-color: #ded5f5;
}

.checkboxLabel {
    margin-bottom: 0;
}

.checkboxLabel_Red {
    margin-bottom: 0;
    color: red;
}

.input_small {
    width: 50px;
}

.eolPad {
    margin-left: 34%;
    margin-top: -3.5%;
}

.input_eol_size {
    width: 100%;
}

.input_siteArea {
    width: 175px;
}

.input_suburb {
    width: 134px;
}

.input_area {
    width: 80px;
}

.select_area {
    width: 120px;
}

.select_pervious_area_type {
    height: 26px;
    margin: 5px;
}

.imp_node_name {
    width: 185px;
}

.select_area_OSD {
    width: 150px;
}

.input_area_pervious {
    width: 70px;
}

.flex-horizontal {
    display: flex;
}

.item-left {
    flex: 1000%;
}

.btn-grad {
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    display: block;
}

.btn-grad:hover {
    background-position: right center;
    /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}

.headerTxt {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 5px #eee;
}

.header {
    background-image: '../../assets/header.png';
    height: 200px;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px white inset;

    transition: background-color 5000s ease-in-out 0s;
    border: 1px solid grey;
}

.imperviousSection {
    z-index: 10;
}

.swinburne_vip_osd {
    background: #ded5f5;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 0px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 20px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.rational_vip_osd {
    background: #fff200;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 0px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 20px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.boyds_vip_osd {
    background: #cedcf8;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 0px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 20px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

/*.spacer {*/
/*  width: 40px;*/
/*}*/

/*.small_spacer {*/
/*  width: 15px;*/
/*}*/

/*.lableFormatterOSD {*/
/*  margin: 2px 5px;*/
/*  width: 80px;*/
/*  text-align: right;*/
/*}*/

/*.lableFormatterAEP {*/
/*  margin: 2px 5px;*/
/*  width: 80px;*/
/*  text-align: right;*/
/*}*/

/*.checkboxPadOSD {*/
/*  padding: 0px;*/
/*  padding-left: 10px;*/
/*  !* padding-left: 0; *!*/
/*  margin: 0;*/
/*}*/

/*.checkboxOSDPad {*/
/*  padding: 10px;*/
/*  padding-left: 0;*/
/*  margin: 0;*/
/*}*/

/*.select_area_OSD {*/
/*  width: 120px;*/
/*}*/

.impervious-first-row {
    display: flex;
    width: 100%;
}

.impervious-title-td {
    flex: 1;
}

.glowing {
    animation: glow 1s ease-in-out infinite alternate;
}
@keyframes glow {
    from {
        text-shadow:
            0 0 10px #fff,
            0 0 20px #fff,
            0 0 30px #fff;
    }

    to {
        text-shadow:
            0 0 20px #f0530a,
            0 0 30px #f0530a,
            0 0 40px #f0530a;
    }
}

.impervious-column-one-third-td-left {
    flex: 1;
    min-width: 400px;
}

.impervious-column-one-third-td-right {
    flex: 2;
}
.eol_result_margin {
    margin: 1px 2px;
    padding-right: 2px;
}

.alert_set_impervious {
    padding: 0.2rem 1.25rem;
    margin-bottom: 0.3rem;
}
.table_line_impervious {
    border-top: 1px solid rgb(149, 186, 209);
}
.eol_tooltip {
    border-radius: 30px;
    text-align: center;
}

.eol-treatment-device-title {
    text-align: right;
    animation: glow 1s ease-in-out infinite alternate;
}
