.wsud-form {
    background-color: #f0f0f0;
    padding: 20px;
    border: 1px solid #ccc;
    transition: height 0.3s ease;
    /* overflow: hidden; */
    justify-content: center;
    margin-bottom: 3vh;
}

.wsud-form h3 {
    font-size: 1.5rem;
    margin-bottom: 3vh;
}
.wsud-form h4 {
    font-weight: 900;
    font-size: 1rem;
    color: rgb(4, 2, 114);
}

.wsud-form label {
    display: block;
    margin-bottom: 0.625rem;
}

.form-header {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    width: auto;
}

.wsud-form .checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    white-space: nowrap;
    justify-content: normal;
    width: auto;
    font-size: x-large;
}

.wsud-form .toggle-checkbox {
    margin: 0;
    padding: 0;
    width: auto;
}

.toggle-button {
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
}

.arrow {
    border: solid #fff;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 5px;
    transition: transform 0.3s ease;
}

.up {
    transform: rotate(-135deg);
}

.down {
    transform: rotate(45deg);
}

.closed {
    height: auto;
}
.wsud-form input,
select {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.wsud-form input:disabled {
    background-color: #ebeaea;
}

.wsud-form select {
    margin: 0;
    padding: 0.5rem;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.625rem;
    cursor: pointer;
    outline: none;
    transition:
        border-color 0.3s,
        box-shadow 0.3s;
    background-image: linear-gradient(45deg, transparent 50%, #666 50%),
        linear-gradient(135deg, #666 50%, transparent 50%);
    background-position:
        calc(100% - 15px) center,
        calc(100% - 10px) center;
    background-size:
        5px 5px,
        5px 5px;
    background-repeat: no-repeat;
}

.wsud-form select:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: #666 transparent transparent transparent;
}

.wsud-form select:disabled {
    background-color: #ebeaea;
}
