.aboveGroundButton {
    /* box-shadow: 0px 0px 0px 2px #9fb4f2; */
    background: linear-gradient(to bottom, #eef700 5%, #a16b0d 100%);
    /* background-color: #a5aa07; */
    /* border-radius: 30px; */
    /* border: 1px solid #4e6096; */
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    /* font-size: 14px; */
    /* padding: 8px 5px; */
    /* text-decoration: none; */
    text-shadow: 0px 1px 0px #283966;
}
.aboveGroundButton:hover {
    background: linear-gradient(to bottom, #a16b0d 5%, #f7ff00 100%);
    background-color: #a16b0d;
}
.aboveGroundButton:active {
    position: relative;
    top: 1px;
}

.belowGroundPipeButton {
    /* box-shadow: 0px 0px 0px 2px #9fb4f2; */
    background: linear-gradient(to bottom, #00f752 5%, #067a0c 100%);
    /* background-color: #a5aa07; */
    /* border-radius: 30px; */
    /* border: 1px solid #4e6096; */
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    /* font-size: 14px; */
    /* padding: 8px 5px; */
    /* text-decoration: none; */
    text-shadow: 0px 1px 0px #283966;
}
.belowGroundPipeButton:hover {
    background: linear-gradient(to bottom, #067a0c 5%, #00f752 100%);
    background-color: #0a8b0a;
}
.belowGroundPipeButton:active {
    position: relative;
    top: 1px;
}

.belowGroundtankButton {
    /* box-shadow: 0px 0px 0px 2px #9fb4f2; */
    background: linear-gradient(to bottom, #00b7ff 5%, #084799 100%);
    /* background-color: #a5aa07; */
    /* border-radius: 30px; */
    /* border: 1px solid #4e6096; */
    display: inline-block;
    cursor: pointer;
    color: #ffffff;
    font-family: Arial;
    /* font-size: 14px; */
    /* padding: 8px 5px; */
    /* text-decoration: none; */
    text-shadow: 0px 1px 0px #283966;
}
.belowGroundtankButton:hover {
    background: linear-gradient(to bottom, #084799 5%, #00b7ff 100%);
    background-color: #083a85;
}
.belowGroundtankButton:active {
    position: relative;
    top: 1px;
}

.panNode_osd {
    background: #ded5f5;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    margin-bottom: 0px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 0 20px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.panNode_osd_NorBE {
    background: #ded5f5;
    border-radius: 5px;
    border-color: #7290cf;
    border-width: 3px;
    border-style: solid;
    margin-bottom: 0px;
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    padding: 20px;
    position: sticky;
    font-family: 'Montserrat', sans-serif;
}

.spacer {
    width: 40px;
}

.small_spacer {
    width: 15px;
}

.lableFormatterOSD {
    margin: 2px 5px;
    width: 80px;
    text-align: right;
}

.lableFormatterAEP {
    margin: 2px 5px;
    width: 80px;
    text-align: right;
}

.checkboxPadOSD {
    padding: 0px;
    padding-left: 10px;
    /* padding-left: 0; */
    margin: 0;
}

.checkboxOSDPad {
    padding: 10px;
    padding-left: 0;
    margin: 0;
}

.select_area_OSD {
    width: 120px;
}

.uploadContainer {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    max-width: 600px;
    margin: 3vh auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.uploadContainer .link {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
}

.uploadContainer .link:hover {
    text-decoration: underline;
}

.uploadContainer .button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    flex-grow: 0;
}

.uploadContainer .button:hover {
    background-color: #0056b3;
}

.uploadContainer .uploadedFile {
    margin-top: 10px;
    color: #555;
    font-style: italic;
}

.osd-container input,
select {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.osd-container input:disabled {
    background-color: #ebeaea;
}

.osd-container select {
    margin: 0;
    padding: 0.5rem;
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 0.625rem;
    cursor: pointer;
    outline: none;
    transition:
        border-color 0.3s,
        box-shadow 0.3s;
    background-image: linear-gradient(45deg, transparent 50%, #666 50%),
        linear-gradient(135deg, #666 50%, transparent 50%);
    background-position:
        calc(100% - 15px) center,
        calc(100% - 10px) center;
    background-size:
        5px 5px,
        5px 5px;
    background-repeat: no-repeat;
}

.osd-container select:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-color: #666 transparent transparent transparent;
}

.osd-container select:disabled {
    background-color: #ebeaea;
}

.osd-form-section-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.osd-form-section {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    justify-content: center;
    align-items: flex-end;
    padding: 0 10px;
    width: 100%;
    white-space: no-wrap;
    /* text-align: center; */
    gap: 5px;
}

.span-2 {
    grid-column: span 2;
}

.span-3 {
    grid-column: span 3;
}

.span-4 {
    grid-column: span 4;
}

.span-5 {
    grid-column: span 5;
}

.span-6 {
    grid-column: span 6;
}

.span-7 {
    grid-column: span 7;
}

.osd-container .delete-icon {
    height: 100%;
    font-size: 1.5rem;
    color: red;
    cursor: pointer;
}

.title-2 {
    font-family: 'Times New Roman';
    font-weight: 700;
    font-size: 20px;
    margin: 0;
}
